import { DropdownSelectOption } from 'components/DropdownSearchSelect/DropdownSearchSelect';
import { ClientApplicationOptions } from 'reduxState/store/application/types';

export interface CreateApplicationOption {
  propertyTarget: keyof ClientApplicationOptions;
  label: string;
}

export const excludedSplitPolicies = new Set([
  'NewPolicyFunction9',
  'SplitPolicyWF100',
  'SplitPolicyWF100',
  'NewPolicyFunction7',
  'SplitPolicyWF35Device65Application0',
  'NewPolicyFunction8',
  'NewPolicyFunction13',
  'NewPolicyFunction12',
  'NewPolicyFunctionEnhancedHalf',
  'NewPolicyFunction14',
]);

export const enhancedSplitPolicies = new Set([
  'SplitPolicyWF35Application65Device0Precision2',
  'NewPolicyFunctionMicrosoftEnhanced',
]);

export const defaultFormData = {
  newCompanyName: '',
  selectedCompany: null,
  applicationType: null,
  splitPolicy: null,
  payoutCurrency: null,
  defaultAppCountry: null,
  serviceableCountries: null,
  CJPID: '',
  nerfRate: '100',
  productPageUrl: '',
};

export const defaultAppOptions = {
  IsAllowList: false,
  DefaultFeaturedMerchants: false,
  MatchProjection: false,
  RequiresITPCompliance: false,
  IsBrowserExtension: false,
  IsCashbackApp: false,
  IsFMTCDisabled: false,
  IsAlwaysRedirect: false,
  RequiresDeviceProfiles: false,
  RequiresDeeplinks: false,
};

export const createApplicationOptionItems: CreateApplicationOption[] = [
  {
    propertyTarget: 'IsBrowserExtension',
    label: 'Browser Extension',
  },
  {
    propertyTarget: 'IsCashbackApp',
    label: 'Cashback App',
  },
  {
    propertyTarget: 'IsFMTCDisabled',
    label: 'FMTC Disabled',
  },
  {
    propertyTarget: 'RequiresITPCompliance',
    label: 'Requires ITP Compliance',
  },
  {
    propertyTarget: 'IsAlwaysRedirect',
    label: 'Always Redirect',
  },
  {
    propertyTarget: 'DefaultFeaturedMerchants',
    label: 'Default Featured Merchants',
  },
  {
    propertyTarget: 'RequiresDeviceProfiles',
    label: 'Cloud Device Profiles',
  },
  {
    propertyTarget: 'RequiresDeeplinks',
    label: 'Requires Deeplinks',
  },
];

export const applicationTypeOptions: DropdownSelectOption[] = [
  {
    label: 'Android SDK',
    value: 'Android SDK',
  },
  {
    label: 'Desktop API',
    value: 'Desktop API',
  },

  {
    label: 'Desktop Chrome Extension',
    value: 'Desktop Chrome Extension',
  },
  {
    label: 'Desktop Chrome Extension Launchpad',
    value: 'Desktop Chrome Extension Launchpad',
  },
  {
    label: 'Desktop Edge Extension',
    value: 'Desktop Edge Extension',
  },
  {
    label: 'Desktop Edge Extension Launchpad',
    value: 'Desktop Edge Extension Launchpad',
  },
  {
    label: 'Desktop Offerwall API',
    value: 'Desktop Offerwall API',
  },
  {
    label: 'Desktop Safari Extension',
    value: 'Desktop Safari Extension',
  },
  {
    label: 'Desktop Safari Extension Launchpad',
    value: 'Desktop Safari Extension Launchpad',
  },
  {
    label: 'Headless Couponator',
    value: 'Headless Couponator',
  },
  {
    label: 'Mobile Android Extension',
    value: 'Mobile Android Extension',
  },
  {
    label: 'Mobile API',
    value: 'Mobile API',
  },
  {
    label: 'Mobile Offerwall API',
    value: 'Mobile Offerwall API',
  },
  {
    label: 'Mobile Safari Extension',
    value: 'Mobile Safari Extension',
  },
  {
    label: 'Mobile Safari Extension Launchpad',
    value: 'Mobile Safari Extension Launchpad',
  },
  {
    label: 'Offer Showcase',
    value: 'Offer Showcase',
  },
];

export const defaultCurrencyOptions: DropdownSelectOption[] = [
  {
    label: 'USD - United States Dollar',
    value: 'USD',
  },
  {
    label: 'EUR - Euro',
    value: 'EUR',
  },
  {
    label: 'JPY - Japanese Yen',
    value: 'JPY',
  },
  {
    label: 'GBP - British Pound Sterling',
    value: 'GBP',
  },
  {
    label: 'AUD - Australian Dollar',
    value: 'AUD',
  },
  {
    label: 'CAD - Canadian Dollar',
    value: 'CAD',
  },
  {
    label: 'CHF - Swiss Franc',
    value: 'CHF',
  },
  {
    label: 'CNY - Chinese Yuan Renminbi',
    value: 'CNY',
  },
  {
    label: 'SEK - Swedish Krona',
    value: 'SEK',
  },
  {
    label: 'NZD - New Zealand Dollar',
    value: 'NZD',
  },
];
