import React, { FC, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { MoreHoriz } from '@mui/icons-material';
import { Button, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAppSelector } from 'reduxState/hooks';
import { RestrictedUserRoles } from 'reduxState/store/user/constants';
import { selectUserRestrictedRole } from 'reduxState/store/user/selectors';
import './OverflowMenu.scss';

interface OverflowMenuProps {
  appId?: string;
  hasWfPermissions: boolean;
  adminAppId?: string;
}

const OverflowMenu: FC<OverflowMenuProps> = ({ appId, hasWfPermissions, adminAppId }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();
  const theme = useTheme();
  const isInSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const restrictedRole = useAppSelector(selectUserRestrictedRole);
  const isInAppGroupOverview = !!adminAppId && !appId;

  const { logout, isAuthenticated } = useAuth0();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (): void => {
    localStorage.removeItem('accessToken');
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  // Hides menu buttons based off Entity 74's role restrictions
  const showSettings = !restrictedRole || restrictedRole === RestrictedUserRoles.ADMIN;
  const showMerchants = !restrictedRole || restrictedRole !== RestrictedUserRoles.FINANCE;

  // will hide/display Payments/Performance page based of Entity74's restrictedUser type
  const hasOpsPermissions = restrictedRole === RestrictedUserRoles.OPERATIONS;

  const baseLinkUrl = `/${adminAppId}/app/${appId}`;
  return (
    <div>
      <Button
        classes={{ root: `menu-button ${anchorEl ? 'active' : ''}` }}
        aria-controls="overflow-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disableRipple
      >
        <MoreHoriz classes={{ root: 'menu-icon' }} fontSize="large" />
      </Button>
      <Menu
        id="overflow-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        PopoverClasses={{ paper: 'overflow-menu-popup' }}
        classes={{ list: 'overflow-menu-list-body' }}
        disableScrollLock
      >
        {isInSmallScreen &&
          adminAppId &&
          (appId
            ? [
                !hasOpsPermissions && (
                  <MenuItem classes={{ root: 'list-item' }} onClick={handleClose} key="summary">
                    <Link
                      to={`${baseLinkUrl}/summary`}
                      className={`link ${location.pathname.includes('summary') ? 'active' : 'inactive'}`}
                    >
                      Performance
                    </Link>
                  </MenuItem>
                ),
                <MenuItem classes={{ root: 'list-item' }} onClick={handleClose} key="commission-history">
                  <Link
                    to={`${baseLinkUrl}/commission-history`}
                    className={`link ${location.pathname.includes('commission-history') ? 'active' : 'inactive'}`}
                  >
                    Commissions
                  </Link>
                </MenuItem>,
                !hasOpsPermissions && (
                  <MenuItem classes={{ root: 'list-item' }} onClick={handleClose} key="payment">
                    <Link
                      to={`${baseLinkUrl}/payment`}
                      className={`link ${location.pathname.includes('payment') ? 'active' : 'inactive'}`}
                    >
                      Payments
                    </Link>
                  </MenuItem>
                ),
                showMerchants && (
                  <MenuItem classes={{ root: 'list-item' }} onClick={handleClose} key="merchants">
                    <Link
                      to={`${baseLinkUrl}/merchants`}
                      className={`link ${location.pathname.includes('merchants') ? 'active' : 'inactive'}`}
                    >
                      Merchants
                    </Link>
                  </MenuItem>
                ),
              ]
            : [
                !hasOpsPermissions && (
                  <MenuItem classes={{ root: 'list-item' }} onClick={handleClose} key="app-group-summary">
                    <Link
                      to={`/${adminAppId}/app-group-summary`}
                      className={`link ${location.pathname.includes('summary') ? 'active' : 'inactive'}`}
                    >
                      Performance
                    </Link>
                  </MenuItem>
                ),
                <MenuItem classes={{ root: 'list-item' }} onClick={handleClose} key="app-group-commission-history">
                  <Link
                    to={`/${adminAppId}/app-group-commission-history`}
                    className={`link ${location.pathname.includes('commission-history') ? 'active' : 'inactive'}`}
                  >
                    Commissions
                  </Link>
                </MenuItem>,
              ])}
        {appId &&
          hasWfPermissions && [
            <MenuItem
              classes={isInSmallScreen ? { root: 'list-item has-divider' } : { root: 'list-item' }}
              onClick={handleClose}
              key="featured-merchant"
            >
              <Link
                to={`${baseLinkUrl}/featured-merchant`}
                className={`link ${location.pathname.includes('featured-merchant') ? 'active' : 'inactive'}`}
              >
                Featured Merchants
              </Link>
            </MenuItem>,
            <MenuItem classes={{ root: 'list-item' }} onClick={handleClose} key="application-management">
              <Link
                to={`${baseLinkUrl}/application-management`}
                className={`link ${location.pathname.includes('application-management') ? 'active' : 'inactive'}`}
              >
                Application Management
              </Link>
            </MenuItem>,
            <MenuItem classes={{ root: 'list-item' }} onClick={handleClose} key="concepts">
              <Link
                to={`${baseLinkUrl}/concepts`}
                className={`link ${location.pathname.includes('concepts') ? 'active' : 'inactive'}`}
              >
                Concepts
              </Link>
            </MenuItem>,
          ]}
        {appId && [
          <MenuItem classes={{ root: 'list-item' }} onClick={handleClose} key="commission-download">
            <Link
              to={`${baseLinkUrl}/commission-download`}
              className={`link ${location.pathname.includes('commission-download') ? 'active' : 'inactive'}`}
            >
              Commission History Downloads
            </Link>
          </MenuItem>,
        ]}
        {appId && showSettings && (
          <MenuItem classes={{ root: 'list-item' }} key="settings">
            <Link
              to={`${baseLinkUrl}/settings`}
              className={`link ${location.pathname.includes('settings') ? 'active' : 'inactive'}`}
            >
              Settings
            </Link>
          </MenuItem>
        )}
        {isInAppGroupOverview && (
          <MenuItem classes={{ root: 'list-item' }} key="app-group-commission-download">
            <Link
              className={`link ${location.pathname.includes('features') ? 'active' : 'inactive'}`}
              to={`/${adminAppId}/app-group-commission-download`}
            >
              Commission History Downloads
            </Link>
          </MenuItem>
        )}
        <MenuItem classes={{ root: 'list-item has-divider' }} key="support">
          <a className="link inactive" href="https://kb.wildfire-corp.com/">
            Support
          </a>
        </MenuItem>
        {hasWfPermissions && [
          <MenuItem classes={{ root: 'list-item' }} key="features">
            <Link className={`link ${location.pathname.includes('features') ? 'active' : 'inactive'}`} to="/features">
              Feature Flags
            </Link>
          </MenuItem>,
          <MenuItem classes={{ root: 'list-item' }} key="create-application">
            <Link
              className={`link ${location.pathname.includes('create-application') ? 'active' : 'inactive'}`}
              to="/create-application"
            >
              Create Application
            </Link>
          </MenuItem>,
        ]}
        {adminAppId && (
          <MenuItem classes={{ root: 'list-item' }} key="boosted-offers">
            <Link
              className={`link ${location.pathname.includes('boosted-offers') ? 'active' : 'inactive'}`}
              to={`/${adminAppId}/boosted-offers`}
            >
              Boosted Offers
            </Link>
          </MenuItem>
        )}
        {isAuthenticated && (
          <MenuItem classes={{ root: 'list-item' }} onClick={handleLogout} key="logout">
            <span className="link inactive">Log Out</span>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default OverflowMenu;
