import React, { FC, ReactElement, useCallback } from 'react';
import { LinearProgress, Table, TableBody } from '@mui/material';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import {
  CombinedColumn,
  CommissionWithMerchantName,
  AppGroupCommissionWithMerchantName,
} from '../../../reduxState/store/commission/types';
import { CommissionHistoryTableHeader } from '../CommissionHistoryTableHeader/CommissionHistoryTableHeader';
import CommissionV2 from '../CommissionV2/CommissionV2';
import './CommissionHistoryTableV2.scss';

export interface CommissionHistoryTableV2Props {
  applicationId?: string;
  columns: CombinedColumn[];
  commissions: CommissionWithMerchantName[] | AppGroupCommissionWithMerchantName[];
  isLoading: boolean;
  hasNextPage: boolean;
  loadMoreItems: () => Promise<void>;
  hasDatesSelected: boolean;
  hasCommissionError: boolean;
  addHeaderMargin: boolean;
  callbackVisible: boolean;
  updateColumns: (columns?: CombinedColumn[]) => void;
}
export const CommissionHistoryTableV2: FC<React.PropsWithChildren<CommissionHistoryTableV2Props>> = ({
  applicationId,
  columns,
  commissions,
  isLoading,
  hasNextPage,
  loadMoreItems,
  hasDatesSelected,
  hasCommissionError,
  addHeaderMargin,
  callbackVisible,
  updateColumns,
}) => {
  const commissionsMessage = useCallback((): ReactElement | null => {
    if (!commissions.length && !isLoading) {
      let message = 'No commissions for the selected time range.';
      if (hasCommissionError) {
        message = 'Something went wrong with retrieving commissions. Please try again.';
      }

      if (!hasDatesSelected) {
        message = 'Please select a date range above to see commission history.';
      }

      return <div className="no-commissions pt-4 text-center">{message}</div>;
    }

    return null;
  }, [isLoading, commissions, hasCommissionError, hasDatesSelected]);

  const isItemLoaded = useCallback((index: number) => !hasNextPage || index < commissions.length, [
    hasNextPage,
    commissions,
  ]);

  const itemCount = hasNextPage ? commissions.length + 1 : commissions.length;

  const checkCommissions = useCallback((): ReactElement | null => {
    if (isLoading) return null;
    if (commissions.length && !isLoading) {
      return (
        <TableBody component="div" className="table-body commission-history-body thin-scrollbar">
          <AutoSizer className="!h-[480px]">
            {/* @ts-ignore */}
            {({ height, width }) => {
              return (
                <InfiniteLoader
                  isItemLoaded={isItemLoaded}
                  loadMoreItems={() => {
                    if (hasNextPage) {
                      loadMoreItems();
                    }
                  }}
                  itemCount={itemCount}
                >
                  {/* @ts-ignore */}
                  {({ onItemsRendered, ref }) => {
                    return (
                      <List
                        height={height || 100}
                        width={width || 100}
                        itemCount={commissions.length}
                        itemSize={50}
                        itemData={{
                          items: commissions,
                          columns,
                          applicationId,
                          callbackVisible,
                        }}
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                        className="thin-scrollbar"
                      >
                        {/* @ts-ignore */}
                        {CommissionV2}
                      </List>
                    );
                  }}
                </InfiniteLoader>
              );
            }}
          </AutoSizer>
        </TableBody>
      );
    }

    return null;
  }, [
    isLoading,
    commissions,
    columns,
    applicationId,
    hasNextPage,
    isItemLoaded,
    itemCount,
    loadMoreItems,
    callbackVisible,
  ]);

  return (
    <div className="table-wrapper flex flex-col flex-1 rounded text-sm">
      <Table role="table" component="div" className="table no-scrollbars">
        <CommissionHistoryTableHeader
          columns={columns}
          className={`commission-table-header${addHeaderMargin ? '-margin' : ''}`}
          callbackVisible={callbackVisible}
          onReorganize={updateColumns}
        />
        {isLoading && <LinearProgress color="secondary" data-testid="loading" />}
        {checkCommissions()}
      </Table>
      {commissionsMessage()}
    </div>
  );
};
