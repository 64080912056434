import React, { FC } from 'react';
import TableHead from '@mui/material/TableHead';
import { CombinedColumn } from '../../../reduxState/store/commission/types';
import DraggableTableHeader from 'components/DraggableTableHeader/DraggableTableHeader';
import { useAppDispatch } from 'reduxState/hooks';
import { setCommissionColumns } from 'reduxState/store/commission/slice';

export interface CommissionHistoryTableHeaderProps {
  columns: CombinedColumn[];
  className?: string;
  callbackVisible: boolean;
  onReorganize: (columns?: CombinedColumn[]) => void;
}

export const CommissionHistoryTableHeader: FC<CommissionHistoryTableHeaderProps> = ({
  columns,
  className,
  callbackVisible,
  onReorganize,
}) => {
  const dispatch = useAppDispatch();

  return (
    <TableHead component="div" sx={{ display: 'table-header-group' }} className={`table-header-container ${className}`}>
      <div className={`table-header-row`} style={{ display: 'flex', flex: '1' }}>
        <DraggableTableHeader initialColumns={columns} onReorganize={onReorganize} callbackVisible={callbackVisible} />
      </div>
    </TableHead>
  );
};
